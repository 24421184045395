export enum Cookie {
  AB_TESTS = 'ab_tests',
  ALGOLIA_QUERY_ID = 'algolia_query_id',
  CONSTRUCTOR_CLIENT_ID = 'ConstructorioID_client_id',
  CONSTRUCTOR_SESSION_ID = 'ConstructorioID_session_id',
  CSRF_TOKEN = '_csrf_token',
  DEBUG = 'debug',
  DISMISSED_LOGIN_GATE = 'dlg_ts',
  EVERLANE_USER = '_everlane_user',
  IS_TESTIM = 'is_testim',
  OAUTH_REDIRECT_PREVIOUS_PATH = 'orpp',
  ONLAND_UTM_MEDIUM = 'onland_utm_medium',
  ONLAND_UTM_SOURCE = 'onland_utm_source',
  SAW_POST_PURCHASE_SURVEY = 'saw_post_purchase_survey',
  SHOW_GDPR = 'show_gdpr',
  SMS_LOGIN_ENABLED = 'sms_login_enabled',
  SMS_MARKETING_OPTED_IN = 'sms_marketing_opted_in',
  SHOP_PAY_SUBJECT_ID = 'shop_pay_subject',
  FORCED_SHOP_PAY = 'forced_shop_pay',
}

export default Cookie
