export enum Collections {
  BREADCRUMB = 'Collections.BREADCRUMB',
  BROWSE_OTHER_CATEGORIES = 'Collections.BROWSE_OTHER_CATEGORIES',
  COLOR_CHANGE = 'Collections.COLOR_CHANGE',
  COLOR_HOVER = 'Collections.COLOR_HOVER',
  CHANGE_LAYOUT = 'Collections.CHANGE_LAYOUT',
  FILTER_CLICK = 'Collections.FILTER_CLICK',
  COLLAPSE_FILTER = 'Collections.COLLAPSE_FILTER',
  HERO = 'Collections.HERO',
  PAGE_VIEW = 'Collections.PAGE_VIEW',
  PRODUCT_CLICK = 'Collections.PRODUCT_CLICK',
  PRODUCT_HOVER = 'Collections.PRODUCT_HOVER',
  PRODUCT_IMAGE_SWIPE = 'Collections.PRODUCT_IMAGE_SWIPE',
  SCROLL_PRODUCT_GROUP_COLORS = 'Collections.SCROLL_PRODUCT_GROUP_COLORS',
  SUBHERO_CLICK = 'Collections.SUBHERO_CLICK',
  UGC_WIDGET_CLICK = 'Collections.UGC_WIDGET_CLICK',
  EASTER_EGG_ALL_CLICK = 'Collections.EASTER_EGG_ALL_CLICK',
  PRODUCT_MOBILE_IMAGE_CHANGE = 'Collections.PRODUCT_MOBILE_IMAGE_CHANGE',
}

export default Collections
