import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useEffect, useRef, useState } from 'react'
import { publishEvent } from 'lib/events/tracking'

import Event from 'types/Event'

declare global {
  interface Window {
    Kustomer: any
  }
}

const KUSTOMER_SCRIPT_ID = 'kustomer-script-420'

const useKustomerLiveChat = () => {
  const { asPath } = useRouter()

  const isChatWidgetStarted = useRef(false)
  const [isLiveChatAvailable, setIsLiveChatAvailable] = useState<boolean>(false)

  const { publicRuntimeConfig } = getConfig() || {}
  const kustomerApiKey = publicRuntimeConfig.KUSTOMER_TRACKING_API_KEY
  const brandId = publicRuntimeConfig.KUSTOMER_TRACKING_BRAND_ID

  const allowedPaths = ['/account/info', '/account/orders']

  const addScriptTag = () => {
    // check if the script already exists. If not, put it in the DOM
    if (!document.getElementById(KUSTOMER_SCRIPT_ID)) {
      const script = document.createElement('script')
      script.id = KUSTOMER_SCRIPT_ID
      script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js'
      script.setAttribute('data-kustomer-api-key', kustomerApiKey)
      window.document.body.appendChild(script)
    }
  }

  const handleCreateConversation = () => {
    publishEvent(Event.Kustomer.LIVE_CHAT_INITIATED)
  }

  const removeKustomerScripts = () => {
    if (window && window.Kustomer) window.Kustomer.stop()
    const script = document.getElementById(KUSTOMER_SCRIPT_ID)
    if (script) script.remove()
  }

  const isAvailable = () => {
    addScriptTag()
    window.addEventListener('kustomerLoaded', () => {
      window.Kustomer.start(
        {
          brandId,
          hideChatIcon: true,
        },
        () => {
          const { availability } = window.Kustomer.isChatAvailable()
          setIsLiveChatAvailable(availability === 'online')
          removeKustomerScripts()
        },
      )
    })
    return isLiveChatAvailable
  }

  const handleKustomerLoaded = () => {
    if (window && window.Kustomer) {
      window.Kustomer.start(
        {
          brandId,
          zIndex: 12,
          chatIconPosition: {
            alignment: 'left',
            verticalPadding: 20,
          },
        },
        () => {
          // We're switching behaviors depending on the treatment the user is in.
          // This is not ideal, but the script and widget are unpredictable otherwise.
          const { availability } = window.Kustomer.isChatAvailable()
          if (availability === 'online') {
            if (asPath === '/checkout') {
              window.Kustomer.open()
              isChatWidgetStarted.current = true
            }

            window.Kustomer.addListener('onConversationCreate', handleCreateConversation)
            setIsLiveChatAvailable(true)
          } else {
            setIsLiveChatAvailable(false)
          }
        },
      )
    }
  }

  const handleStartKustomer = async () => {
    if (!isChatWidgetStarted.current) {
      await addScriptTag()
      window.addEventListener('kustomerLoaded', handleKustomerLoaded)
    } else {
      window.Kustomer.open()
    }
  }

  useEffect(() => {
    if (!allowedPaths.includes(asPath)) removeKustomerScripts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  return {
    isLiveChatAvailable,
    handleStartKustomer,
    isAvailable,
  }
}

export default useKustomerLiveChat
