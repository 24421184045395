import getConfig from 'next/config'
import { Autotrack, Events } from '@everlane/event-tracking'
import AutoTrack from 'lib/events/autoTrack'
import EventPropertiesFilter from 'lib/events/propertyFilter'

import Event from 'types/Event'

import WebClientEvent, {
  EventName,
  EventWithoutProperties,
  EventProperties,
} from 'types/WebClientEvent'

const eventQueue = []
const subscriptionQueue = []
const EventHistory = {}
let isInitialized = false
let EventTracker = null

const isEditingBuilder = () => {
  if (!global.document?.location?.href) return false
  const { searchParams } = new URL(global.document.location.href)
  return searchParams.getAll('builder.preview').length > 0
}

const setupEventTracking = async ({
  sessionId,
  userId,
  isMobile,
}: {
  sessionId: string
  userId: number
  isMobile: boolean
}) => {
  if (isInitialized || !sessionId) return EventTracker
  isInitialized = true

  const resetInitialization = () => {
    if (EventTracker) {
      EventTracker.cleanUp()
    }
    EventTracker = null
    isInitialized = false
    return null
  }

  try {
    // Don’t set up event tracking during server-side rendering
    if (!(global.window && global.window.HAS_LOGGED_ENGAGEMENT)) resetInitialization()

    // Don't send events when previewing in Builder editor
    if (isEditingBuilder()) resetInitialization()

    const { publicRuntimeConfig } = getConfig()
    const isProduction = publicRuntimeConfig.RELEASE_STAGE === 'production'

    const trackerName = isProduction ? 'EventTracker' : 'LogOnlyTracker'
    const Tracker = (await import(`@everlane/event-tracking/src/${trackerName}`)).default

    const intervalMS = 2500 // flush the event queue every 2500ms; default is 10s
    // we know that 500ms is too short for high traffic days.

    EventTracker = new Tracker({
      apiKey: publicRuntimeConfig.EVENT_TRACK_API_KEY,
      apiUrl: publicRuntimeConfig.EVENT_TRACK_API_URL,
      properties: () => ({
        platform: isMobile ? 'mobile' : 'desktop',
        session_id: sessionId,
        user_id: userId,
      }),
      interval: intervalMS,
    })

    Autotrack.initialize(EventTracker, Event, EventPropertiesFilter)
  } catch (e) {
    resetInitialization()
  }

  while (subscriptionQueue.length) {
    const { event, callback } = subscriptionQueue.pop()
    Events.subscribe(event, callback)
  }

  while (eventQueue.length) {
    const { event, attributes } = eventQueue.pop()
    Events.publish(event, attributes)
  }

  return EventTracker
}

export function clearEventTracking() {
  if (EventTracker) {
    EventTracker.cleanUp()
    EventTracker = null
  }
}

export async function publishEvent(event: EventWithoutProperties): Promise<void>
export async function publishEvent<T extends EventName>(
  event: T,
  properties: EventProperties<WebClientEvent, T>,
): Promise<void>
export async function publishEvent<T extends EventName>(event: T): Promise<void>
export async function publishEvent(event: string, properties?: object) {
  const attributes = properties || {}
  const autoTrack = AutoTrack[event] !== false

  const eventObject = { name: event, autoTrack }

  if (isInitialized) {
    Events.publish(eventObject, attributes)
  } else {
    // These events go into the Everlane data pipeline
    eventQueue.push({ event: eventObject, attributes })

    // These events get fired with subscribed callbacks
    if (EventHistory[event]) {
      EventHistory[event].push(attributes)
    } else {
      EventHistory[event] = [attributes]
    }
  }
}

export async function subscribeToEvent<T extends EventName>(
  event: T,
  callback: (properties: EventProperties<WebClientEvent, T>) => void,
): Promise<void>
export async function subscribeToEvent<T extends EventName>(
  event: T,
  callback: (properties: EventProperties<WebClientEvent, T>) => void,
  { late }: { late: boolean },
): Promise<void>
export async function subscribeToEvent(event: string, callback: Function, { late = false } = {}) {
  if (!isInitialized) {
    subscriptionQueue.push({ event, callback })
    return
  }

  if (late && EventHistory[event]) {
    EventHistory[event].forEach(attributes => callback(attributes))
    // prefer setting null instead of deleting because we repeatedly set and clear the same keys
    EventHistory[event] = null
  }
  Events.subscribe(event, callback)
}

export async function unsubscribeFromEvent<T extends EventName>(
  event: T,
  callback: (properties: EventProperties<WebClientEvent, T>) => void,
): Promise<void>
export async function unsubscribeFromEvent(event: string, callback: Function) {
  if (isInitialized) {
    Events.unsubscribe(event, callback)
  } else {
    const enqueuedIndex = subscriptionQueue.findIndex(
      enqueued => enqueued.event === event && enqueued.callback === callback,
    )

    if (enqueuedIndex > -1) {
      subscriptionQueue.splice(enqueuedIndex, 1)
    }
  }
}

export default setupEventTracking
